





























import { Notify,Dialog,Toast } from "vant";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import Stroage from "../../util/Storage"
import { AuthentItemType } from "../../Type/index";
import { Vue, Component } from "vue-property-decorator";
import { GetAutionList } from "../../Api/Basics/index";
import { AuthentStore } from "../../store/modules/Basics/Authent";
import ZoomPage from "@/impView/PageSafety";

interface AuthentListType<T = string> {
  AwaitIcon:T;
  YesIcon:T;
  NotIcon:T;
  NoIcon:T;
  List: Array<AuthentItemType>;
  userId:T|number;
  token:T;
  loadingShow:boolean;

  handleToPath(data: AuthentItemType,index:number): void;
  handleUpData():void;
  handleFitlerData( data:Array<AuthentItemType> ):void;
  handleError(data: string): void;
}

@Component({ name: "AuthentList",components:{ HeadTop } })
export default class AuthentList extends ZoomPage implements AuthentListType {
  YesIcon = require("../../assets/icon/Authent/auther.png");
  AwaitIcon = require("../../assets/icon/Authent/auditAuther.png");
  NotIcon = require("../../assets/icon/Authent/notAuther.png");
  NoIcon = require("../../assets/icon/Authent/NoState.png")
  List: any[] = [
    {
        name:"学生认证",
        useful:"万能互助等用户端所有功能权限",
        image:require("../../assets/icon/Authent/student.png"),
        state:"Not"
    },{
        name:"骑手认证",
        useful:"万能互助等用户端所有功能权限",
        image:require("../../assets/icon/Authent/bike.png"),
        state:"No"
    },{
        name:"商户认证",
        useful:"万能互助等用户端所有功能权限",
        image:require("../../assets/icon/Authent/shop.png"),
        state:"Await"
    },{
        name:"企业认证",
        useful:"万能互助等用户端所有功能权限",
        image:require("../../assets/icon/Authent/enterprise.png"),
        state:"Yes"
    }
  ];
  userId = "";
  token = "";
  loadingShow = true

  mounted() {
    this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
    this.token = Stroage.GetData_ && Stroage.GetData_("token")
    if( AuthentStore.GetAuthList.length > 0 ){
      this.handleFitlerData( AuthentStore.GetAuthList  )
      this.loadingShow = false
    }
    this.handleUpData()
  }

  handleToPath(data: AuthentItemType,index:number) {
    switch ( Number( data.status ) ) {
        case 0:
            this.$router.push({
              path:"/autherIdentity",
              query:{
                authType:data.authType as string,
                name:data.name
              }
            })
            break;
        case 1:
            Dialog.confirm({
              title: '温馨提醒',
              message: '您之前的审核 已被拒绝，是否还继续重新实名认证呢？',
              confirmButtonText:"重新认证",
              cancelButtonText:"不用了",
              className:"HideClassName",
              overlayClass:"MaskHideClassName"
            }).then( (res:any)=>{
              this.$router.push({
                path:"/auther",
                query:{
                  authType:"0",
                  name:data.name
                }
              })
            } ).catch( (cat:any)=>cat)
            break;
        case 2:
            // this.handleError("正在审核中...")
            Toast({
              message:"正在审核中...",
              icon:"none",
              duration:1500,
              className:"ToastClassName"
            })
            break;
        case 3:
            if( AuthentStore.GetState.length ){
              if( index != Number( AuthentStore.GetState ) ){
                AuthentStore.Clear()
              }
            }else{
              AuthentStore.SetState(""+index)
            }
            this.$router.push({
                path:"/auther",
                query:{
                  authType:data.authType as string,
                  name:data.name
                }
              })
            break;
    }
  }

  handleUpData(){
    GetAutionList({
      userId:this.userId,
      token:this.token
    }).then( (res:any)=>{
      this.loadingShow = false
      if( res.message.code === "200" ){
        this.handleFitlerData( res.data )
        AuthentStore.SetAuthList( res.data )
      }else{
        this.handleError( res.message.msg )
      }
    } )
  }

  handleFitlerData( data:Array<AuthentItemType> ){
      if ( !Array.isArray( data ) )return;
      let List :Array<any> = [];
        data.forEach( (item:any,index:number)=>{
            switch ( Number(item.authType) ) {
                case 0:
                    item.image = require("../../assets/icon/Authent/student.png")
                    break;
                case 1:
                    item.image = require("../../assets/icon/Authent/bike.png")
                    break;
                case 2:
                     item.image = require("../../assets/icon/Authent/shop.png")
                    break;
                case 3:
                     item.image = require("../../assets/icon/Authent/enterprise.png")
                    break;
            }
            List.push( item )
            switch ( Number(item.status) ) {
                case 0:
                    List[index].state = "Yes"
                    break;
                case 1:
                    List[index].state = "No"
                    break;
                case 2:
                    List[index].state = "Await"
                    break;
                case 3:
                    List[index].state = "Not"
                    break;
            }
        } )
      this.List = List
  }

  handleError(data: string) {
    let str = "";
    if (data.length) {
      str = data;
    } else {
      str = "网络繁忙...";
    }
    Notify({
      message: str,
      color: "white",
      background: "rgba(0,0,0,.6)",
    });
  }
}
